<template>
  <SidePopup
    title="Aplicar categorias a um grupo de produtos"
    v-on:close="onClose"
    @submit="onSubmit"
    overlay-not-back
  >
    <ArvoreCategorias v-model="selecionados" />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormButton from '@/components/form/FormButton'
import ArvoreCategorias from './ArvoreCategoria'

export default {
  name: 'ProdutoAssociarModel',
  components: { SidePopup, FormButton, ArvoreCategorias },
  data: () => ({
    selecionados: []
  }),
  methods: {
    onSubmit() {
      this.$emit('submit', this.selecionados)
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.seletor {
  margin: 0px !important;
  .v-input__slot {
    margin: 0px !important;
  }
  .v-messages {
    display: none !important;
  }
}
</style>
