var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButton',{attrs:{"text":"Vincular imagens de produto","to":"/fornecedor/cadastro/imagens-produto","type":"secundario"}}),_c('FormButtonMenu',{attrs:{"type":"secundario","options":_vm.operacoesOptions,"loading":_vm.loadingAcao},on:{"click":function (op) { return _vm.selectOperacao(op); }}},[_vm._v(" Operação em massa ")]),_c('FormButtonMenu',{attrs:{"type":"secundario","options":_vm.planilhaOptions},on:{"click":function (op) { return _vm.selectOperacao(op); }}},[_vm._v(" Operação em planilha ")]),_c('FormButton',{attrs:{"text":"Novo produto","type":"primario"},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"filters":_vm.filters,"sortBy":"nome","sortDesc":false,"showSelect":"","search":"","noHideFilters":"","auto-search":false},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"tem_imagem",fn:function(ref){
var row = ref.row;
var col = ref.col;
return [(col)?_c('Chip',{attrs:{"color":"green","text":"Sim","small":true}}):_c('Chip',{attrs:{"color":"cyan","text":"Não","small":true}})]}},{key:"granularidade",fn:function(ref){
var row = ref.row;
var col = ref.col;
return [(col && (col > 1 || col < 1))?_c('Chip',{attrs:{"color":"green","text":"Sim","small":true}}):_c('Chip',{attrs:{"color":"cyan","text":"Não","small":true}})]}},{key:"idproduto",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions,"loading":_vm.loadingRow[row.idcadastroextraproduto]},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}]),model:{value:(_vm.produtoSelecionados),callback:function ($$v) {_vm.produtoSelecionados=$$v},expression:"produtoSelecionados"}})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[(_vm.modalEdicaoEmPlanilha.open)?_c('EdicaoEmPlanilha',{attrs:{"modelo":_vm.modalEdicaoEmPlanilha.modelo},on:{"close":function($event){_vm.modalEdicaoEmPlanilha.open = false}}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[(_vm.modalAssociar)?_c('ProdutoAssociarModal',{on:{"close":function($event){_vm.modalAssociar = false},"submit":_vm.aplicaCategorias}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[(_vm.modalAssociarTags)?_c('ProdutoAssocialTags',{on:{"close":function($event){_vm.modalAssociarTags = false},"submit":_vm.aplicarTags}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }