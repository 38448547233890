<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Vincular imagens de produto"
            to="/fornecedor/cadastro/imagens-produto"
            type="secundario"
          />
          <FormButtonMenu
            type="secundario"
            :options="operacoesOptions"
            @click="op => selectOperacao(op)"
            :loading="loadingAcao"
          >
            Operação em massa
          </FormButtonMenu>
          <FormButtonMenu
            type="secundario"
            :options="planilhaOptions"
            @click="op => selectOperacao(op)"
          >
            Operação em planilha
          </FormButtonMenu>
          <FormButton text="Novo produto" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :filters="filters"
        sortBy="nome"
        :sortDesc="false"
        @click="onClick"
        ref="datatable"
        showSelect
        search
        noHideFilters
        v-model="produtoSelecionados"
        :auto-search="false"
      >
        <template v-slot:tem_imagem="{ row, col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-else color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:granularidade="{ row, col }">
          <Chip
            v-if="col && (col > 1 || col < 1)"
            color="green"
            text="Sim"
            :small="true"
          />
          <Chip v-else color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:idproduto="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.idcadastroextraproduto]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <EdicaoEmPlanilha
        v-if="modalEdicaoEmPlanilha.open"
        :modelo="modalEdicaoEmPlanilha.modelo"
        @close="modalEdicaoEmPlanilha.open = false"
      />
    </transition>
    <transition name="slide-popup" mode="out-in">
      <ProdutoAssociarModal
        v-if="modalAssociar"
        @close="modalAssociar = false"
        @submit="aplicaCategorias"
      />
    </transition>
    <transition name="slide-popup" mode="out-in">
      <ProdutoAssocialTags
        v-if="modalAssociarTags"
        @close="modalAssociarTags = false"
        @submit="aplicarTags"
      />
    </transition>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import { CARACTERISTICA_PRODUTO_LIST } from '@/store/actions/caracteristicaProduto'
import { FORMA_PAGAMENTO_LIST } from '@/store/actions/formaPagamento'
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { mapGetters } from 'vuex'
import {
  PRODUTO_REMOVE,
  PRODUTO_DESASSOCIAR,
  PRODUTO_ASSOCIAR,
  PRODUTO_DESASSOCIAR_TAGS,
  PRODUTO_ASSOCIAR_TAGS
} from '@/store/actions/produto'
import { SETOR_FOLHA_LOAD } from '@/store/actions/setor'
import { SEGMENTO_LIST } from '@/store/actions/segmento'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import Chip from '@/components/Chip'
import ProdutoAssociarModal from './ProdutoAssociarModal'
import ProdutoAssocialTags from './ProdutoAssociarTags'
import EdicaoEmPlanilha from '@/components/EdicaoEmPlanilha'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'ProdutosTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    Chip,
    FormButtonGroup,
    ProdutoAssociarModal,
    ProdutoAssocialTags,
    EdicaoEmPlanilha,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Nome', value: 'nome' },
      {
        text: 'Código de barras',
        value: 'codigobarras'
      },
      {
        text: 'Código interno',
        value: 'id_produto_erp'
      },
      {
        text: 'Agrupador Emb.',
        value: 'agrupador_embalagem'
      },
      {
        text: 'Unidade de Venda',
        value: 'label_unidade'
      },
      {
        text: 'Tem imagem',
        value: 'tem_imagem'
      },
      {
        text: 'Venda fracionada',
        value: 'granularidade'
      },
      {
        text: 'Opções',
        value: 'idproduto',
        clickable: false
      }
    ],
    tableOptions: [
      { text: 'Deletar', value: 'delete' },
      { text: 'Produto similar', value: 'cadastra_produto_similar' }
    ],
    operacoesOptions: [
      { text: 'Associar categorias', value: 'associar_categoria' },
      { text: 'Desassociar categorias', value: 'desassociar_categoria' },
      { text: 'Associar tags', value: 'associar_tags' },
      { text: 'Desassociar tags', value: 'desassociar_tags' },
      { text: 'Deletar produtos', value: 'delete' }
    ],
    planilhaOptions: [
      { text: 'Editar produtos', value: 'planilha_produto' },
      {
        text: 'Editar vinculo categorias',
        value: 'planilha_vinculo_categoria'
      },
      { text: 'Editar vinculo tags', value: 'planilha_vinculo_tag' },
      {
        text: 'Editar caracteristica produto',
        value: 'planilha_vinculo_caracteristica'
      }
    ],
    produtoSelecionados: [],
    modalEdicaoEmPlanilha: {
      open: false,
      modelo: 'planilha_produto'
    },
    modalAssociar: false,
    modalProdutoSimilar: false,
    modalAssociarTags: false,
    loadingAcao: false,
    loadingRow: {},
    modulosForm: [
      ModulesEnum.PORTFOLIO_WEB,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'moduloFornecedorAtivo',
      'getFornecedorTag'
    ]),
    tipoVenda() {
      return [
        { text: 'Todos', value: '' },
        { text: 'Avulso', value: 'AVULSO' },
        { text: 'Recorrência', value: 'RECORRENCIA' },
        { text: 'Sob Demanda', value: 'SOB_DEMANDA' }
      ]
    },
    filters() {
      return [
        { label: 'Código de barras', query: 'codigobarras' },
        { label: 'Código interno', query: 'codigointerno' },
        { label: 'Agrupador de embalagem', query: 'agrupador' },
        {
          label: 'Disponibilidade',
          query: 'disponibilidade',
          default: '',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Disponível', value: 'true' },
            { text: 'Indisponível', value: 'false' }
          ]
        },
        {
          label: 'Vinculo de imagem',
          query: 'temimagem',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Com imagem', value: 'true' },
            { text: 'Sem imagem', value: 'false' }
          ]
        },
        {
          label: 'Tipo venda',
          query: 'tipovenda',
          options: this.tipoVenda
        },
        {
          label: 'Categorização',
          query: 'temcategoria',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Categorizado', value: 'true' },
            { text: 'Não categorizado', value: 'false' }
          ]
        },
        {
          label: 'Exibe se indisponível',
          query: 'ativo',
          default: '',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Sim', value: 'true' },
            { text: 'Não', value: 'false' }
          ]
        },
        {
          label: 'Venda fracionada',
          query: 'fracionado',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Com venda fracionada', value: 'true' },
            { text: 'Sem venda fracionada', value: 'false' }
          ]
        },
        {
          label: 'Múltipo',
          query: 'multiplo',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Com multiplo', value: 'true' },
            { text: 'Sem multipo', value: 'false' }
          ]
        }
      ]
    },
    endpoint() {
      return `/api/v1/produtos/datableformat?idfornecedor=${this.getFornecedorId}&fp=`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  watch: {
    getFornecedorId: {
      handler() {
        this.loadData()
      }
    }
  },
  created() {
    this.loadData()
    this.getPaymentMethod()
  },
  methods: {
    getPaymentMethod() {
      this.$store.dispatch(FORMA_PAGAMENTO_LIST, {
        idfornecedor: this.getFornecedorId
      })
    },
    loadData() {
      this.$store.dispatch(SEGMENTO_LIST)
      this.$store.dispatch(SETOR_FOLHA_LOAD)
      this.$store.dispatch(CARACTERISTICA_PRODUTO_LIST)
      this.loadingRow = {}
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    onClick(item) {
      this.$router.push(
        `produto/${item.idproduto}/${item.idcadastroextraproduto}`
      )
    },
    onCadastraProdutoSimilar(item) {
      this.$router.push(
        `produto/similar/${item.idproduto}/${item.idcadastroextraproduto}`
      )
    },
    onNew() {
      this.$router.push('produto/novo')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
        case 'cadastra_produto_similar':
          return this.onCadastraProdutoSimilar(item)
      }
    },
    selectOperacao(option) {
      const edicaoPlanilha = option.value.startsWith('planilha')
      if (edicaoPlanilha) {
        this.modalEdicaoEmPlanilha = {
          open: true,
          modelo: option.value
        }
        return
      }
      if (this.produtoSelecionados.length == 0) {
        this.$vueOnToast.pop(
          'warning',
          'Necessário selecionar ao menos um produto para aplicar operações em massa'
        )
        return
      }
      switch (option.value) {
        case 'associar_categoria':
          this.modalAssociar = true
          break
        case 'cadastra_produto_similar':
          this.modalProdutoSimilar = true
          break
        case 'desassociar_categoria':
          return this.onDesassociarCategorias()
        case 'associar_tags':
          this.modalAssociarTags = true
          break
        case 'desassociar_tags':
          return this.onDesassociarTags()
        case 'delete':
          return this.onDeleteMultiple()
      }
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão do produto '${item.nome}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idcadastroextraproduto]: true
      }
      this.$store
        .dispatch(PRODUTO_REMOVE, item.idcadastroextraproduto)
        .then(() => {
          this.$vueOnToast.pop('success', 'Produto removido com sucesso')
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
        })
        .catch(() => {
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
        })
    },
    onDeleteMultiple() {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão de ${this.produtoSelecionados.length} produtos selecionado?`
      }
      this.confirmSwal(options, () => this.deleteMultiple())
    },
    async deleteMultiple() {
      try {
        this.loadingAcao = true
        for (const i in this.produtoSelecionados) {
          const produto = this.produtoSelecionados[i]
          await this.$store.dispatch(
            PRODUTO_REMOVE,
            produto.idcadastroextraproduto
          )
        }
        this.loadingAcao = false
        this.$vueOnToast.pop('success', 'Produtos removido com sucesso')
        this.$refs.datatable.reload()
      } catch (err) {
        this.loadingAcao = false
        this.$vueOnToast.pop('error', 'Não foi possivel deletar produtos')
      }
    },
    onDesassociarCategorias() {
      const options = {
        text: 'Deseja desassociar as categorias destes produtos?',
        title: `Desassociação de Categorias`
      }
      this.confirmSwal(options, () => {
        this.loadingAcao = true
        const arrayIdsProdutos = this.produtoSelecionados.map(
          item => item.idproduto
        )
        this.$store
          .dispatch(PRODUTO_DESASSOCIAR, arrayIdsProdutos)
          .then(() => {
            this.loadingAcao = false
            this.$vueOnToast.pop(
              'success',
              'Produtos desassociados da categoria com sucesso!'
            )
          })
          .catch(() => (this.loadingAcao = false))
      })
    },
    onDesassociarTags() {
      const options = {
        text: 'Deseja desassociar as tags destes produtos?',
        title: `Desassociação de tags de produto`
      }
      this.confirmSwal(options, () => {
        this.loadingAcao = true
        const arrayIdsProdutos = this.produtoSelecionados.map(
          item => item.idcadastroextraproduto
        )
        this.$store
          .dispatch(PRODUTO_DESASSOCIAR_TAGS, {
            idscadastroextra: arrayIdsProdutos
          })
          .then(() => {
            this.loadingAcao = false
            this.$vueOnToast.pop(
              'success',
              'Produtos desassociados as tags de pesquisa com sucesso!'
            )
          })
          .catch(() => (this.loadingAcao = false))
      })
    },
    aplicaCategorias(categorias) {
      this.modalAssociar = false
      this.loadingAcao = true
      const arrayIdsProdutos = []
      const arrayIdsCadastroExtra = []
      this.produtoSelecionados.forEach(produto => {
        arrayIdsProdutos.push(produto.idproduto)
        arrayIdsCadastroExtra.push(produto.idcadastroextraproduto)
      })
      this.$store
        .dispatch(PRODUTO_ASSOCIAR, {
          setores: categorias,
          alvos: {
            produtos: arrayIdsProdutos,
            idscadastroextra: arrayIdsCadastroExtra
          }
        })
        .then(() => {
          this.loadingAcao = false
          this.$vueOnToast.pop(
            'success',
            'Produtos associados as categorias com sucesso!'
          )
        })
        .catch(() => (this.loadingAcao = false))
    },
    aplicarTags({ tags, substituir }) {
      this.loadingAcao = true
      const arrayIdsProdutos = this.produtoSelecionados.map(
        item => item.idcadastroextraproduto
      )
      const arrayIdstag = tags.map(item => item.idtagpesquisa)
      this.$store
        .dispatch(PRODUTO_ASSOCIAR_TAGS, {
          idscadastroextra: arrayIdsProdutos,
          idstagpesquisas: arrayIdstag,
          substituir
        })
        .then(() => {
          this.loadingAcao = false
          this.modalAssociarTags = false
          this.$vueOnToast.pop(
            'success',
            'Produtos associados as tags de pesquisa com sucesso!'
          )
        })
        .catch(() => (this.loadingAcao = false))
    }
  }
}
</script>
