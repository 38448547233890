<template>
  <SidePopup
    title="Aplicar tags a um grupo de produtos"
    v-on:close="onClose"
    @submit="onSubmit"
    overlay-not-back
  >
    <FormTags
      placeholder="Pesquise e adicione tags"
      v-model="selecionados"
      :endpoint="endpointTags"
      keyid="idtagpesquisa"
      keyvalue="valor"
      :search-with-spaces="true"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.text }}</p>
          <small v-if="item.small">Cod.: {{ item.descricao }}</small>
          <small v-if="item.agrupadores"
            >Agr.:{{ item.agrupadores.join('|') }}</small
          >
        </div>
      </template>
    </FormTags>
    <FormSelect
      label="Como aplicar essa atualização?"
      v-model="substituir"
      :options="seletorOptions"
    />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormButton from '@/components/form/FormButton'
import FormTags from '@/components/form/FormTags'
import FormSelect from '@/components/form/FormSelect'
import { mapGetters } from 'vuex'

export default {
  name: 'ProdutoAssociarTags',
  components: { SidePopup, FormButton, FormTags, FormSelect },
  data: () => ({
    selecionados: [],
    substituir: true,
    seletorOptions: [
      {
        text: 'Substituir tags existentes',
        value: true
      },
      {
        text: 'Adicionar às tags existentes',
        value: false
      }
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId']),
    endpointTags() {
      return `/api/v4/tag-pesquisa/${this.getFornecedorId}`
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', {
        tags: this.selecionados,
        substituir: this.substituir
      })
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.seletor {
  margin: 0px !important;
  .v-input__slot {
    margin: 0px !important;
  }
  .v-messages {
    display: none !important;
  }
}
</style>
